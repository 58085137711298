/* DESKTOP */
.explosion-wrapper {
  position: fixed;
  width: 5000px;
  height: 2000px;
  pointer-events: none;
  top: 0;
  left: 0;
  overflow: hidden;
  user-select: none;
}
.cat-explosion-button {
  position: fixed;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  pointer-events: all;
  border: none;
  background-color: #ffcc00;
  border-radius: 5px;
  top: 0px;
  left: -4200px;
  /*margin-left: 5500px;*/
  user-select: none;
}
.emoji {
  position: absolute;
  font-size: 24px;
  left: 50%;
  right: auto;
  animation: fly 1s ease-out forwards; 
  pointer-events: none;
  user-select: none;
}


/* MOBILE */
@media (max-width: 768px) {
  .explosion-wrapper {
    position: fixed;
    pointer-events: none;
    margin-left: -200px;
    overflow: auto;
    display: block;
    user-select: none;
    z-index: 99999;
  }
  .cat-explosion-button {
    position: fixed;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    pointer-events: all;
    border: none;
    background-color: #ffcc00;
    border-radius: 5px;
    top: 0px;
    left: -4200px;
    /*margin-left: 4200px;*/
    user-select: none;
  }
  .emoji {
    padding: 30px;
    width: 300px;
    max-width: 30px;
    position:absolute;
    user-select: none;
   /* margin-left: -450px;*/
    /*z-index: 99999;*/
  }
}


/*********************************************************************************************************/
@keyframes fly {
  0% {
    transform: translate(1550, 370) scale(2500);
    opacity: 1;
  }
  85% {
    transform: translate(1550, 370) scale(2500);
    opacity: 1;
  }
  100% {
    transform: translate(var(--moveX), var(--moveY)) scale(2.0);
    opacity: 0;
  }
}



/*********************************************************************************************************/



.cat-audio-explosion-wrapper{
  position: fixed;
  width: 5000px;
  height: 2000px;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index:-99999;
  overflow: hidden;
  user-select: none;
}
.cat-audio-explosion-button{
  position: fixed;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  pointer-events: all;
  border: none;
  background-color: #ffcc00;
  border-radius: 5px;
  top: 200px;
  left: -4200px;
  /*margin-left: 5500px;*/
  transform:translateY(200px);
  user-select: none;
}

/* MOBILE */
@media (max-width: 768px) {
  .cat-audio-explosion-wrapper {
    position: fixed;
    pointer-events: none;
    margin-left: -800px;
    overflow: auto;
    display: block;
    z-index: 9999;
    user-select: none;
  }
  .cat-audio-explosion-button {
    position: fixed;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    pointer-events: all;
    border: none;
    background-color: #ffcc00;
    border-radius: 5px;
    top: 0px;
    left: -4200px;
    /*margin-left: 4200px;*/
    z-index: 9999;
    transform:translateY(200px);
    user-select: none;
  }
}
